<script>
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'
import Multiselect from "vue-multiselect";
import {getDropDownElementsByTypeApi} from "@/api/common";
import Swal from "sweetalert2";
export default {
    components: { 
      Multiselect,
      Dataset,
      DatasetItem,
      DatasetInfo,
      DatasetPager,
      // DatasetSearch,
    //   DatasetShow,
    },
    props:['animal'],
    data() {
        return {
            did:false,
            selectedOps:"",
            selectedKey:"",
            operations:[],
            operationsList:[],
            operationForm:{
                operation_location:"garden",
                operation_type:1,
                designation:"",
                operation_date:"",
                comments:""
            },
            cols: [
                {
                    name: 'Type Opération',
                    field: 'operation_type',
                    sort: ''
                },
                {
                    name: 'Désignation',
                    field: 'designation',
                    sort: ''
                },
                {
                    name: 'Date de l\'opération',
                    field: 'operation_date',
                    sort: ''
                },
                {
                    name: 'l\'intervenant',
                    field: 'user',
                    sort: ''
                },
            ]
        }
    },
    watch:{
        animal:{
            handler(val){
                if(val.length > 0) {
                    this.operationForm.animal_id=val[0].id;
                    this.did = true;
                    this.OpsList();
                    this.ListOpsTypes();
                    // this.operationForm.animal_id = val[0].id
                }
                else this.resetData();
            },
        }
    },
    mounted(){
        // this.OpsList();
    },
    methods:{
        selectRow(row,index){
            if(this.selectedKey == index) {this.selectedRows = []; this.selectedKey = -1; this.resetData()}
            else {
                this.selectedRows = [row];
                this.selectedKey = index;

                this.operationForm = {
                    operation_location: row.operation_location,
                    animal_id         : row.animal_id,
                    operation_type    : row.operation_type,
                    designation       : row.designation,
                    operation_date    : row.operation_date,
                    comments          : row.comments
                }

                this.selectedOps.push(row.operation_types);
            }
        },
        resetData(){
            this.operationForm = {
                animal_id         : 0,
                operation_location: "zoo",
                operation_type    : "",
                designation       : "",
                operation_date    : "",
                comments          : ""
            };
            this.selectedOps="";
            this.operations=[];
            this.did = false;
            // this.selectedRows=[];
            // this.selectedKey="";
        },
        ListOpsTypes(){
            getDropDownElementsByTypeApi("park_operation")
            .then(res => {
                // console.log(res);
                this.operationsList = res.data.original.list
            })
            .catch(error => (console.log(error)));
        },

        OpsList(){
            this.$http.post('/referentiel/zoo/operations/list/'+this.animal[0].id,this.operationForm)
            .then(response => {
                console.log(response);
                this.operations = response.data;
            })
            .catch(error => (console.log(error)))
        },
        addOps(){
            Swal.fire({
                title: 'Êtes vous sûr ?',
                text: "d'ajouter cette opération ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2b8a3e',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$http.post('/referentiel/zoo/operations/store',this.operationForm)
                    .then(response => {
                        this.OpsList();
                        this.resetData();
                        // Swal.fire("Félicitations!", "l'Operation a été enregistré", "success");
                        this.$toast.success("l'opération  a été enregistré");
                    })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("Un probléme s'est produit, Veuillez réessayer");
                    })
                }
            })
            
        },
        updateOps(){
            Swal.fire({
                title: 'Êtes vous sûr ?',
                text: "d'ajouter cette opération ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2b8a3e',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$http.post('/referentiel/zoo/operations/update',this.operationForm)
                    .then(response => {
                        this.OpsList();
                        this.resetData();
                        // Swal.fire("Félicitations!", "l'Operation a été enregistré", "success");
                        this.$toast.success("les données on été mis à jour");
                    })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("Un probléme s'est produit, Veuillez réessayer");
                    })
                }
            })
        },
        getOperationID(operation){
            this.operationForm.operation_type = operation.id;
        }
    }
}
</script>

<template>
    <div class="row">
        <div class="col">
            <div v-if="operations.length > 0" class="accordion mb-2" role="tablist">
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-x style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                        <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des Opérations</div>
                        <div class="col text-right">
                            <i  class="mdi mdi-chevron-down font-size-18"></i> 
                            <!-- <i v-else class="mdi mdi-chevron-down font-size-18"></i>  -->
                        </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-x"  accordion="my-accordion" role="tabpanel">
                    <b-card-body class="shadow-lg">
                        <div class=" mb-0 shadow">
                            <dataset
                                v-slot="{ ds }"
                                :ds-data="operations"
                            >
                                
                                <div class="row">
                                <div class="col-md-12">
                                    <div class="">
                                    <table class="table table-hover d-md-table">
                                        <thead>
                                        <tr>
                                            <th v-for="(th) in cols" :key="th.field">
                                            {{ th.name }} 
                                            </th>
                                        </tr>
                                        </thead>
                                        <dataset-item tag="tbody">
                                        <template #default="{ row, rowIndex }">
                                            <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedKey == rowIndex}" style="cursor:pointer">
                                            <th>
                                                {{ row.operation_types.designation }}
                                            </th>
                                            <th>
                                                {{ row.designation }}
                                            </th>
                                            <td>{{ row.operation_date_format }}</td>
                                            <td>{{ row.user.firstname +" "+row.user.lastname }}</td>
                                            </tr>
                                        </template>
                                        </dataset-item>
                                    </table>
                                    </div>
                                </div>
                                </div>
                                <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                                <dataset-info class="mb-2 mb-md-0" />
                                <dataset-pager />
                                </div>
                            </dataset>
                        </div>
                    </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <fieldset :disabled="!did">
                    <div class="row mb-2">
                        <div class="col">
                            <label for="">Type d'opération</label>
                            <multiselect v-model="selectedOps" :class="{'disabledMS':!did}" placeholder=""  @select="getOperationID" class="here" :deselect-label="''" :select-label="''" track-by="id" label="designation" :searchable="false" :allow-empty="false" :options="operationsList"></multiselect> 
                        </div>
                        <div class="col">
                            <label for="">Désignation</label>
                            <input type="text" class="form-control" v-model="operationForm.designation">
                        </div>
                        <div class="col">
                            <label for="">Date de l'opération</label>
                            <input type="date" class="form-control" v-model="operationForm.operation_date">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label for="">Commentaire</label>
                            <textarea v-model="operationForm.comments" class="form-control" cols="30" rows="1"></textarea>
                        </div>
                    </div>
                    </fieldset>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col text-right">
                    <b-button v-if="animal.length > 0"  @click="addOps" variant="primary" class="btn-label mr-2">
                        <i class="far fa-save text-white label-icon"></i> Enregitrer
                    </b-button>
                    <b-button v-if="animal.length > 0 && selectedOps.length >0"  @click="updateOps" variant="primary" class="btn-label mr-2">
                        <i class="far fa-save text-white label-icon"></i> Enregitrer
                    </b-button>

                    <b-button v-if="animal.length > 0"  @click="resetData" variant="light" class="btn-label mr-2">
                        <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                    </b-button>


                </div>
            </div>
        </div>
    </div>
</template>