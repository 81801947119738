<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import fiche from "./components/fiche";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'
import Multiselect from "vue-multiselect";
import Fiche from './components/fiche.vue';

// import Swal from "sweetalert2";
// import { required } from "vuelidate/lib/validators";
// import { fetchSettings } from "./api";

export default {
    page: {
        title: "Plantarium",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: { 
      Layout, PageHeader,
      Multiselect,
      Dataset,
      DatasetItem,
      // DatasetInfo,
      DatasetPager,
      // DatasetSearch,
      DatasetShow,
      Fiche
    },
    data() {
    return {
      tableData: [],
      listBases:[],
      selectedBases:[],
      total_ods_month:0,
      disableAll:false,
      currentTotal:0,
      title: "PLANTARIUM",
      search: "",
      List_ODS: [],
      selectedContract:{catering_amnt: 0},
      selectedCTT:[],
      refresh:false,
      active:false,
      selectedRows:[],
      ODS:undefined,
      contracts_List:[],
      bases:[],
      animals:[
          // {id:'F129-6',animal_name:'Vache',age:6, status:'alive',introduced:'24/06/2019',lifebase:'IRARA'},
          // {id:'F421-3',animal_name:'Mouton',age:2, status:'alive',introduced:'24/06/2019',lifebase:'IRARA'},
          // {id:'F702-1',animal_name:'Chèvre',age:2, status:'alive',introduced:'24/06/2019',lifebase:'IRARA'},
          // {id:'F082-3',animal_name:'Chameau',age:3, status:'alive',introduced:'24/06/2019',lifebase:'IRARA'},
      ],
      items: [
        {
          text: "Master Data",
          to: {name:"master_data"},
        },
        {
          text: "Referentiel",
          to: {name:"master_data.referentiels"},
        },
        {
          text: "Plantarium",
          active: true,
        },
      ],
      cols: [
        {
          name: 'Animal',
          field: 'animal',
          sort: ''
        },
        {
          name: 'Base de vie',
          field: 'lifebase',
          sort: ''
        },
        {
          name: 'Age (ans)',
          field: 'age',
          sort: ''
        },
        {
          name: 'Quantité',
          field: 'qty',
          sort: ''
        },
        {
          name: 'Statut',
          field: 'status',
          sort: ''
        },
      ],
      selectedIndex:-1,
      filtredODS:[],
      selectedBdv:[],
    };
  },
  mounted(){
    this.getAnimalsList();
  },
  watch:{
      currentTotal:{
        handler(val){
          // console.log(val);
        }
      },
      refresh:{
          handler(val){
            console.log(val);
            this.refreshODS();
          }
      },
      contracts_List:{
          handler(val){
            console.log(val);
          }
      }
  },
  methods:{
    selectRow(row,index){
      if(this.selectedIndex == index) {this.selectedRows = []; this.selectedIndex = -1;}
      else {
        this.selectedRows = [row];
        this.selectedIndex = index;
        
      }
    },
    searchList(){
      this.$http.post('/referentiel/plantarium/search',{search:this.search,lifebases:this.selectedBases.id})
      .then(response => {
        this.animals = response.data;
        if(this.animals.length == 0) {
          this.$toast.warning('Aucun enregistrement trouvé');
          this.refreshODS();
          this.selectedBases={};
          this.search=""
        }
      })
      .catch(error => console.log(error))
    },
    resetFilter(){
      this.selectedCTT=[];
      this.selectedBdv=[];
      this.filtredODS = this.List_ODS;
    },
    getAnimalsList(){
      this.$http.post('/referentiel/plantarium/list')
      .then(response => {
          // console.log(response.data);
          this.animals = response.data;
          
      })
      .catch(error => console.log(error))
    },
    refreshODS(){
      this.getAnimalsList();
      this.refresh = false;
      this.selectedRows=[];
      this.selectedIndex=-1;
    },
    getContractID(contract){
      if(contract.id == 0){ this.filtredODS = this.List_ODS }
      else{
        this.filtredODS = this.List_ODS;
        this.selectedContract = contract;
        let ods         = this.filtredODS.filter(x            => x.contract.id == contract.id);
        this.bases      = contract.camps_services.map(element => {return element.camp});
        this.filtredODS = ods;
      }
    },
    getLifeBaseID(lifebase){
      let ods = this.filtredODS.filter(element => element.Bdv == lifebase.id);

      this.filtredODS = ods;
    },
  }
    
}
</script>
<template>
    <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-0 style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des Plantes Disponibles</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-0"  accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
                <div class=" mb-0 shadow">
                      <dataset
                        v-slot="{ ds }"
                        :ds-data="animals"
                      >
                        <div class="row mb-1 d-flex justify-content-between align-items-center">
                          <div class="col-md-1 mb-2 mb-md-0">
                            <span class="h5">Recherche :</span> 
                          </div>
                          <div class="col-md-5 mb-2 mb-md-0">
                            <input type="text" class="form-control" v-model="search" placeholder="Saisir la plante ou le N° de série à rechercher">
                          </div>
                          <div class="col-md-5 mb-2 mb-md-0">
                            <multiselect v-model="selectedBases" class="mt-1" track-by="id" label="name" :deselect-label="''" placeholder="Selectionner Une Base" :select-label="''"  :searchable="true" :allow-empty="true" :options="listBases"></multiselect>
                          </div>
                          <div class="col-md-1 mb-2 mb-md-0 text-right">
                            <button class="btn btn-info" @click="searchList">Recherche</button>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="">
                              <table class="table table-hover d-md-table">
                                <thead>
                                  <tr>
                                    <th v-for="(th) in cols" :key="th.field">
                                      {{ th.name }} 
                                    </th>
                                  </tr>
                                </thead>
                                <dataset-item tag="tbody">
                                  <template #default="{ row, rowIndex }">
                                    <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                                      <th>{{ row.plant_name }}</th>
                                      <td>{{ row.lifebase.name }}</td>
                                      <td>{{ row.age }}</td>
                                      <td>{{ row.quantity ? row.quantity : 0 }}</td>
                                      <td><span class="badge badge-primary"> {{row.status_label.designation}} </span></td>
                                      <!-- <td>{{ row.totalAmnt.withCurrency }}</td>
                                      <td v-html="row.status_label"></td> -->
                                    </tr>
                                  </template>
                                </dataset-item>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                          <dataset-show :ds-show-entries="5" />
                          <dataset-pager />
                        </div>
                      </dataset>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        
        <div class="card mt-3">
           <fiche @listBases="listBases = $event" :animal="selectedRows" @refresh="refresh=$event"></fiche>
        </div>
      </div>
    </div>
    </Layout>
</template>